import React from 'react';
import {Helmet} from 'react-helmet';
import {GiHamburgerMenu} from 'react-icons/gi'

import './App.css';

import About from './pages/About';
import CurriculumVitae from './pages/CurriculumVitae';
import Hobbies from './pages/Hobbies';
import Contact from './pages/Contact';
import Navigation from './pages/Navigation';

const header_style = 
{
  color: '#000000',
  backgroundColor: '#CDCDCD',
  borderBottom: '5px solid #EC9B2A',
  position: 'fixed',
  top: '0',
  width: '100%',
  height: '90px',
  overflow: 'auto',
  zIndex: '1',
};

const header_button_style = 
{
  color: '#000000',
  backgroundColor: '#CDCDCD',
  height: '90px',
  width: '25%',
  marginTop: '0',
  marginBottom: '0',
  border: '0',
  fontSize: '40px',
};

const selected_button_style = 
{
  color: '#000000',
  backgroundColor: '#CDCDCD',
  height: '90px',
  width: '25%',
  marginTop: '0',
  marginBottom: '0',
  border: '0',
  fontSize: '40px',
  fontWeight: 'bold',
};

const mobile_button_style = 
{
  color: '#000000',
  backgroundColor: '#CDCDCD',
  height: '90px',
  width: '100%',
  marginTop: '0',
  marginBottom: '0',
  border: '0',
  fontSize: '40px',
  textAlign: 'left',
};

export default class App extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = 
    {
      page_name: 'About',
      last_page_name: 'About',
      page: <About/>,
      desktop: window.innerWidth > 800,
    }
  }

  componentDidMount()
  {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount()
  {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () =>
  {
    this.setState({desktop: window.innerWidth > 800});
  }

  setPage = (page_name) => 
  {
    switch(page_name)
    {
      case 'About':
        this.setState({page: <About desktop={this.state.desktop}/>, page_name: page_name});
      break;
      case 'CV':
        this.setState({page: <CurriculumVitae/>, page_name: page_name});
      break;
      case 'Hobbies':
        this.setState({page: <Hobbies/>, page_name: page_name});
      break;
      case 'Contact':
        this.setState({page: <Contact/>, page_name: page_name});
      break;
      case ' ':
        if (this.state.page_name !== ' ')
        {
          this.setState({page: <Navigation setPage={this.setPage} page_name=' '/>, page_name: page_name, last_page_name: this.state.page_name});
        }
        else
        {
          switch(this.state.last_page_name)
          {
            case 'About':
              this.setState({page: <About desktop={this.state.desktop}/>, page_name: this.state.last_page_name});
            break;
            case 'CV':
              this.setState({page: <CurriculumVitae/>, page_name: this.state.last_page_name});
            break;
            case 'Hobbies':
              this.setState({page: <Hobbies/>, page_name: this.state.last_page_name});
            break;
            case 'Contact':
              this.setState({page: <Contact/>, page_name: this.state.last_page_name});
            break;
            default:
              alert('HOW DID YOU GET HERE');
          }
        }
      break;
      default:
        alert('HOW DID YOU GET HERE');
    }
  }
  
  select_style(button)
  {
    if (button === this.state.page_name)
    {
      return selected_button_style;
    }
    else
    {
      return header_button_style;
    }
  }

  select_header()
  {
    if (this.state.desktop)
    {
      return (
      <div>
        <button style={this.select_style('About')} className='navButton' onClick={() => {this.setPage('About')}}>
          About
        </button>
        <button style={this.select_style('CV')} className='navButton' onClick={() => {this.setPage('CV')}}>
          CV
        </button>
        <button style={this.select_style('Hobbies')} className='navButton' onClick={() => {this.setPage('Hobbies')}}>
          Hobbies
        </button>
        <button style={this.select_style('Contact')} className='navButton' onClick={() => {this.setPage('Contact')}}>
          Contact
        </button>
      </div>
      );
    }
    else
    {
      return (
        <div>
          <button style={mobile_button_style} className='mobileNavButton' onClick={() => {this.setPage(' ')}}>
            <GiHamburgerMenu style={{color: '#000000', marginBottom: '-5px'}}/> {this.state.page_name}
          </button>
        </div>
        );
    }
  }
  
  render()
  {  
    return (
      <div>
        <Helmet>
          <style>{'body {color: #000000 !important; background-color: #E1E1E1;}'}</style>
        </Helmet>
        <div style={header_style}>
          {this.select_header()}
        </div>
        <div>
          {this.state.page}
        </div>
    </div>
    );
  }
}