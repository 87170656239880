import React from 'react'
import {RiDownload2Line} from 'react-icons/ri'
import {RiExternalLinkLine} from 'react-icons/ri'

const page_style =
{
    paddingTop: '80px',
    margin: 'auto',
    width: '90%',
    maxWidth: '950px',
    color: '#000000',
};

const link_style =
{
    textDecoration: 'none',
    color: '#000000',
};

const line_style =
{
    height: '3px',
    backgroundColor: '#EC9B2A',
    borderWidth: '0',
};

const image_style =
{
    display: 'block',
    width: '100%',
    height: '100%',
};

const music_table_style =
{
    marginLeft  : '0',
    marginRight : 'auto',
    tableLayout : 'fixed',
    width: '100%'
};

const name_style =
{
    textAlign: 'left',
    width: '50%'
};

const audio_style =
{
    textAlign: 'right',
    width: '50%'
};

const player_style =
{
    width : '40vw',
    maxWidth: '300px',
    zIndex: '0',
};

class Hobbies extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            desktop: window.innerWidth > 800,
        }
    }

    componentDidMount()
    {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount()
    {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () =>
    {
      this.setState({desktop: window.innerWidth > 800});
    }

    render()
    {
        const pottery_grid_style =
        {
            display: 'grid',
            gridTemplateColumns: this.state.desktop ? '1fr 1fr 1fr' : '1fr 1fr',
            gridGap: '3px',
        };

        return(
        <div style={page_style}>

            <br></br>

            <h1>GAMES</h1>

            <h3><a style={link_style} href='https://oxidius.com/' target='_blank' rel='noreferrer'> Oxidius <RiExternalLinkLine style={{marginBottom: '-2px'}}/></a></h3>
            <p>
                Oxidius is a table-top roleplaying game system that I developed
                with feedback from friends and colleagues.
                Oxidius emphasizes immersive character design
                and fast-paced, realistic combat scenarios.
                At Oxidius.com, players can read through the rules
                and create characters for their campaigns.
            </p>

            <h3><a style={link_style} href='https://grufle.com/' target='_blank' rel='noreferrer'> GRUFLE <RiExternalLinkLine style={{marginBottom: '-2px'}}/></a></h3>
            <p>
                GRUFLE is a grid-based puzzle game.
                The goal of GRUFLE is to slide pieces on a grid on to goal tiles.
                However, every piece has two possible directions it could move.
                They will move one direction when clicked on,
                and a different direction when a different piece is clicked on.
                Players are scored based on how quickly they can move every piece onto a goal tile.
                For GRUFLE, I designed the game and coded the backend and
                <a style={link_style} href='https://www.linkedin.com/in/stephen-harnais-6971a5a0' target='_blank' rel='noreferrer'> <u>Stephen Harnais</u> </a>
                coded the front end.
            </p>

            <h3><a style={link_style} href='http://www.lifedots.net' target='_blank' rel='noreferrer'> Life Dots <RiExternalLinkLine style={{marginBottom: '-2px'}}/></a></h3>
            <p>
                Life Dots is an evolution simulator in which independent agents,
                called dots, perceive and interact with their environment.
                Dots can move, consume food and each other, grow, and reproduce.
                Each dot's behavior is controlled by a separate neural network
                which evolves genetically with the rest of the dot's traits,
                which determine how effective they are at various survival strategies.
                The player has access to a configuration panel in which
                they can set up the conditions of the dots' environment
                as well as tools to interact with the environment during the simulation.
                Over time, dots evolve to thrive in their environment.
            </p>

            <h3><a style={link_style} href='games/ShrineOfTheLily.zip'> Shrine of the Lily <RiDownload2Line style={{marginBottom: '-2px'}}/></a></h3>

            <p>
                Shrine of the Lily is a two player board game
                that takes about 45 minutes to play.
                Players take turns moving their pieces around the board,
                with the goal of moving their lily piece to the
                center tile of the board for three turns.
                Each of the seven types of pieces move differently,
                and have different effects as they move around the board.
                The version available for download is a two-player Java application.
            </p>

            <hr style={line_style}></hr>

            <h1>POTTERY</h1>

            <div style={pottery_grid_style}>
               <img style={image_style} src='pottery/pottery_1.jpg' alt='pottery'/>
               <img style={image_style} src='pottery/pottery_2.jpg' alt='pottery'/>
               <img style={image_style} src='pottery/pottery_3.jpg' alt='pottery'/>
               <img style={image_style} src='pottery/pottery_4.jpg' alt='pottery'/>
               <img style={image_style} src='pottery/pottery_5.jpg' alt='pottery'/>
               <img style={image_style} src='pottery/pottery_6.jpg' alt='pottery'/>
            </div>

            <hr style={line_style}></hr>

            <h1>MUSIC</h1>

            Future Club by Perturbator and Style by Taylor Swift are very similar.

            <table style={music_table_style} cellSpacing='25'>
                <tbody>
                <tr>
                    <td style={name_style}>
                        <h3>Future Style</h3>
                    </td>
                    <td style={audio_style}>
                        <audio controls style={player_style} className='navButton'>
                            <source src='music/Future Style.mp3' type='audio/mpeg' />
                        </audio>
                    </td>
                </tr>
                </tbody>
            </table>

            <h3>Selections from my Music Minor</h3>

            These are rough, but the transition from acoustic piano
            to synthesizer in the capstone is good, and the ten
            seconds of fugue at the end of the week 5 assignment are good.

            <table style={music_table_style} cellSpacing='25'>
                <tbody>
                <tr>
                    <td style={name_style}>
                        <h3>Music Minor Capstone</h3>
                    </td>
                    <td style={audio_style}>
                        <audio controls style={player_style} className='navButton'>
                            <source src='music/Music Minor Capstone.mp3' type='audio/mpeg' />
                        </audio>
                    </td>
                </tr>
                <tr>
                    <td style={name_style}>
                        <h3>Electronic Music Week 5</h3>
                    </td>
                    <td style={audio_style}>
                        <audio controls style={player_style} className='navButton'>
                            <source src='music/Electronic Music Week 5.mp3' type='audio/mpeg' />
                        </audio>
                    </td>
                </tr>
                </tbody>
            </table>

            <hr style={line_style}></hr>

            <br></br><br></br><br></br>
        </div>
        );
    }
}

export default Hobbies
