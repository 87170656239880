import React from 'react'

const page_style =
{
    paddingTop: '100px',
    margin: 'auto',
    width: '90%',
    maxWidth: '950px',
    color: '#000000',
};

const title_style =
{
    margin: '0',
};

const image_style =
{
    marginBottom: '10px',
    marginRight: '10px',
};

const mobile_image_style =
{
    margin: 'auto',
    display: 'block',
};

const line_style =
{
    height: '3px',
    backgroundColor: '#EC9B2A',
    borderWidth: '0',
};

const link_style =
{
    textDecoration: 'none',
    color: '#000000',
};

class About extends React.Component
{

    constructor(props)
    {
        super(props);
        this.state =
        {
            desktop: window.innerWidth > 800,
        }
    }

    componentDidMount()
    {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount()
    {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () =>
    {
      this.setState({desktop: window.innerWidth > 800});
    }

    select_display()
    {
        if (this.state.desktop)
        {
            return(
            <div>
                <img style={image_style} src='profile.png' alt='Profile' height='400' align='left'/>
            </div>
            );
        }
        else
        {
            return(
            <div>
                <img style={mobile_image_style} src='profile.png' alt='Profile' height='400' align='bottom'/>
                <br></br>
            </div>
            );
        }
    }

    render()
    {
        return(
        <div style={page_style}>
            <br></br><br></br>
            {this.select_display()}

            <h2 style={title_style}>Hi, I'm Ethan.</h2>

            <p>I am currently a post-doc at EPFL in the
                <a style={link_style} href='https://www.epfl.ch/labs/ml4ed/'>
                    <b> Machine Learning for Education Lab</b>
                </a>
                . I graduated with a PhD in Data Science from Worcester Polytechnic Institute. At WPI, I worked in the
                <a style={link_style} href='https://new.assistments.org/'>
                    <b> ASSISTments Lab</b>
                </a>
                , where I developed the Automatic Personalized Learning Service, which uses novel reinforcement learning algorithms to personalize students' tutoring.</p>
            <a style={link_style} href='https://doi.org/10.1145/3565472.3592955' target='_blank' rel='noreferrer'>
                <p><b>Click here</b> for an example of my research.</p>
            </a>
            <p>In my spare time I enjoy creating games, making pottery, playing music, and foraging for edible plants and fungi. If you need a learning algorithm, mug, keyboard player, or chanterelles, I might be able to help.</p>
            <p>Thanks for visiting and feel free to get in touch! </p>
            <hr style={line_style}></hr>

            <br></br><br></br><br></br>
        </div>
        );
    }
}

export default About
