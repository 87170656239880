import React from 'react'

const page_style =
{
    paddingTop: '100px',
    margin: 'auto',
    width: '90%',
    maxWidth: '950px',
    color: '#000000',
    textAlign: 'center',
};

const line_style =
{
    height: '3px',
    backgroundColor: '#EC9B2A',
    borderWidth: '0'
};

class Contact extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {}
    }

    render()
    {
        return(
        <div style={page_style}>

            <br></br>

            <h3>If you are interested in collaborating, feel free to email me.</h3>

            <h1>
                ethan.prihar@epfl.ch
            </h1>

            <hr style={line_style}></hr>

            <br></br><br></br><br></br>
        </div>
        );
    }
}

export default Contact
