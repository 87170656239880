import React from 'react'

import '../App.css';

const nav_style = 
{
    color: '#000000',
    paddingTop: '95px',
    margin: 'auto',
    width: '100%',
};

const header_button_style = 
{
    color: '#000000',
    backgroundColor: '#CDCDCD',
    height: '90px',
    width: '100%',
    marginTop: '0',
    marginBottom: '0',
    border: '0',
    fontSize: '40px',
};

class Navigation extends React.Component
{
    render()
    {
        return (
        <div style={nav_style}>
            <button style={header_button_style} className='navButton' onClick={() => {this.props.setPage('About')}}>
                About
            </button>
            <br></br>
            <button style={header_button_style} className='navButton' onClick={() => {this.props.setPage('CV')}}>
                CV
            </button>
            <br></br>
            <button style={header_button_style} className='navButton' onClick={() => {this.props.setPage('Hobbies')}}>
                Hobbies
            </button>
            <br></br>
            <button style={{...header_button_style, borderBottom: '5px solid #EC9B2A'}} className='navButton' onClick={() => {this.props.setPage('Contact')}}>
                Contact
            </button>
        </div>
        );
    }
}

export default Navigation
