import React from 'react'
import {SiGooglescholar} from 'react-icons/si'
import {RiFileDownloadLine} from 'react-icons/ri'
import {RiExternalLinkLine} from 'react-icons/ri'

const page_style =
{
    paddingTop: '80px',
    margin: 'auto',
    width: '90%',
    maxWidth: '950px',
    color: '#000000',
};

const link_style =
{
    textDecoration: 'none',
    color: '#000000',
};

const line_style =
{
    height: '3px',
    backgroundColor: '#EC9B2A',
    borderWidth: '0'
};

const tag_style =
{
    fontSize: '14px',
    fontWeight: '550',
    borderRadius: '20px',
    paddingTop: '3.5px',
    paddingBottom: '5px',
    paddingLeft: '9px',
    paddingRight: '10px',
    display: 'inline-block',
    marginTop: '4px',
    marginRight: '4px',
};

const silver_tag_style =
{
    ...tag_style,
    backgroundColor: '#C0C0C0',
};

const gold_tag_style =
{
    ...tag_style,
    backgroundColor: '#FFD700',
};

class CurriculumVitae extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {}
    }

    render()
    {
        return (
        <div style={page_style}>

            <br></br>

            <h1>AWARDS AND HONORS</h1>

            <h3>
                Poster Presentation Finalist
            </h3>
            <ul>
                February 2023, WPI 2023 Graduate Research Innovation Exchange
            </ul>

            <h3>
                Best Publicly Available Educational Data Set
                <a style={link_style} href='https://osf.io/59shv/' target='_blank' rel='noreferrer'>
                    <span> </span><RiExternalLinkLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                July 2022, 15th International Conference on Educational Data Mining
            </ul>

            <h3>
                Poster Presentation Finalist
            </h3>
            <ul>
                February 2022, WPI 2022 Graduate Research Innovation Exchange
            </ul>

            <h3>
                Best Dataset
                <a style={link_style} href='https://osf.io/9pgv5/' target='_blank' rel='noreferrer'>
                    <span> </span><RiExternalLinkLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                June 2022, 9th ACM Conference on Learning at Scale
            </ul>

            <h3>
                Best Paper Nominee
                <a style={link_style} href='https://dl.acm.org/doi/abs/10.1145/3491140.3528267' target='_blank' rel='noreferrer'>
                    <span> </span><RiExternalLinkLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                June 2022, 9th ACM Conference on Learning at Scale
            </ul>

            <h3>
                Best Paper in Workshop
                <a style={link_style} href='https://neurips.cc/virtual/2021/workshop/21828#wse-detail-32284' target='_blank' rel='noreferrer'>
                    <span> </span><RiExternalLinkLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                December 2021, NeurIPS 2021 MathAI4Ed Workshop
            </ul>

            <h3>
                Poster Presentation Finalist
            </h3>
            <ul>
                February 2020, WPI 2020 Graduate Research Innovation Exchange
            </ul>

            <h3>
                Best Paper
                <a style={link_style} href='https://asmedigitalcollection.asme.org/GT/proceedings-abstract/GT2019/58677/V006T05A004/1067008' target='_blank' rel='noreferrer'>
                    <span> </span><RiExternalLinkLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                June 2019, Turbo EXPO: Power for Land, Sea, and Air
            </ul>

            <h3>
                Robotics Honors Society Membership
            </h3>
            <ul>
                April 2016, WPI Chapter of Rho Beta Epsilon
            </ul>

            <hr style={line_style}></hr>

            <h1>
                PUBLICATIONS
                <a style={link_style} href='https://scholar.google.com/citations?user=QIr4dxAAAAAJ' target='_blank' rel='noreferrer'>
                    <span> </span><SiGooglescholar style={{marginBottom: '-3px'}}/>
                </a>
            </h1>

            <h2>
                Journal Papers
            </h2>

            <h3>
            Using Auxiliary Data to Boost Precision in the Analysis of A/B Tests on an Online Educational Platform: New Data and New Results
                <a style={link_style} href='publications/Using Auxiliary Data to Boost Precision in the Analysis of AB Tests on an Online Educational Platform New Data and New Results.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Sales, A. C., Prihar, E. B., Gagnon-Bartsch, J. A., & Heffernan, N. T. (2023). Using Auxiliary Data to Boost Precision in the Analysis of A/B Tests on an Online Educational Platform: New Data and New Results. Journal of Educational Data Mining, 15(2), (pp. 53–85).
            </ul>

            <h2>
                Full Papers
            </h2>

            <h3>
                Investigating the Impact of Skill-Related Videos on Online Learning
                <a style={link_style} href='publications/Investigating the Impact of Skill-Related Videos on Online Learning.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Prihar, E., Haim, A., Shen, T., Sales, A., Lee, D., Wu, X., & Heffernan, N. (2023, July). Investigating the Impact of Skill-Related Videos on Online Learning. In Proceedings of the Tenth ACM Conference on Learning at Scale (pp. 4-13).
            </ul>

            <h3>
                A Bandit You Can Trust
                <a style={link_style} href='publications/A Bandit You Can Trust.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Prihar, E., Sales, A., & Heffernan, N. (2023, June). A Bandit You Can Trust. Proceedings of the 31st ACM Conference on User Modeling, Adaptation and Personalization (pp. 106–115).
            </ul>

            <h3>
                Exploring Common Trends in Online Educational Experiments
                <a style={link_style} href='publications/Exploring Common Trends in Online Educational Experiments.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Prihar, E., Syed, M., Ostrow, K., Shaw, S., Sales, A., & Heffernan, N. (2022, July). Exploring Common Trends in Online Educational Experiments. In Proceedings of the 15th International Conference on Educational Data Mining. International Educational Data Mining Society (pp. 1-12).
                <br></br>
                <div style={gold_tag_style}>Best Dataset</div>
            </ul>

            <h3>
                Deep Learning or Deep Ignorance? Comparing Untrained Recurrent Models in Educational Contexts
                <a style={link_style} href='publications/Deep Learning or Deep Ignorance Comparing Untrained Recurrent Models in Educational Contexts.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Botelho, A., Prihar, E., & Heffernan, N. (2022, July). Deep Learning or Deep Ignorance? Comparing Untrained Recurrent Models in Educational Contexts. Artificial Intelligence in Education (pp. 281-293). Springer, Cham.
            </ul>

            <h3>
                Automatic Interpretable Personalized Learning
                <a style={link_style} href='publications/Automatic Interpretable Personalized Learning.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Prihar, E., Haim, A., Sales, A., & Heffernan, N. (2022, June). Automatic Interpretable Personalized Learning. In Proceedings of the Ninth ACM Conference on Learning at Scale (pp. 1-11).
                <br></br>
                <div style={gold_tag_style}>Best Dataset</div>
                <div style={silver_tag_style}>Best Paper Nominee</div>
            </ul>

            <h3>
                Toward Personalizing Students' Education with Crowdsourced Tutoring
                <a style={link_style} href='publications/Toward Personalizing Students Education with Crowdsourced Tutoring.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Prihar, E., Patikorn, T., Botelho, A., Sales, A., & Heffernan, N. (2021, June). Toward Personalizing Students' Education with Crowdsourced Tutoring. In Proceedings of the Eighth ACM Conference on Learning at Scale (pp. 37-45).
            </ul>

            <h3>
                Classifying Math Knowledge Components via Task-Adaptive Pre-Trained BERT
                <a style={link_style} href='publications/Classifying Math Knowledge Components via Task-Adaptive Pre-Trained BERT.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Shen, J. T., Yamashita, M., Prihar, E., Heffernan, N., Wu, X., McGrew, S., & Lee, D. (2021, June). Classifying Math Knowledge Components via Task-Adaptive Pre-Trained BERT. In Artificial Intelligence in Education (pp. 408-419). Springer, Cham.
            </ul>

            <h3>
                The Effect of an Intelligent Tutor on Performance on Specific Posttest Problems
                <a style={link_style} href='publications/The Effect of an Intelligent Tutor on Performance on Specific Posttest Problems.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Sales, A., Prihar, E., Heffernan, N., & Pane, J. F. (2021, June). The Effect of an Intelligent Tutor on Performance on Specific Posttest Problems. In Proceedings of the 14th International Conference on Educational Data Mining (pp. 206-215). International Educational Data Mining Society.
            </ul>

            <h3>
                Extending Engine Gas Path Analysis Using Full Flight Data
                <a style={link_style} href='publications/Extending Engine Gas Path Analysis Using Full Flight Data.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Tang, L., Volponi, A. J., & Prihar, E. (2019, June). Extending Engine Gas Path Analysis Using Full Flight Data. In Turbo Expo: Power for Land, Sea, and Air (Vol. 58677, p. V006T05A004). American Society of Mechanical Engineers.
                <br></br>
                <div style={gold_tag_style}>Best Paper</div>
            </ul>

            <br></br>

            <h2>
                Short Papers
            </h2>

            <h3>
                Effective Evaluation of Online Learning Interventions with Surrogate Measures
                <a style={link_style} href='publications/Effective Evaluation of Online Learning Interventions with Surrogate Measures.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Prihar, E., Vanacore, K., Sales, A., & Heffernan, N. (2023, July). Effective Evaluation of Online Learning Interventions with Surrogate Measures. In Educational Data Mining (pp. 1-7). International Educational Data Mining Society.
            </ul>

            <h3>
                A Novel Algorithm For Aggregating Crowdsourced Opinions
                <a style={link_style} href='publications/A Novel Algorithm For Aggregating Crowdsourced Opinions.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Prihar, E., & Heffernan, N. (2021, June). A Novel Algorithm For Aggregating Crowdsourced Opinions. In Educational Data Mining (pp. 547-552). International Educational Data Mining Society.
            </ul>

            <h3>
                Identifying Struggling Students by Comparing Online Tutor Clickstreams
                <a style={link_style} href='publications/Identifying Struggling Students by Comparing Online Tutor Clickstreams.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Prihar, E., Moore, A., & Heffernan, N. (2021, June). Identifying Struggling Students by Comparing Online Tutor Clickstreams. In Artificial Intelligence in Education (pp. 290-295). Springer, Cham.
            </ul>

            <h3>
                Cyther: a human-playable, self-tuning robotic zither.
                <a style={link_style} href='publications/Cyther, a human-playable, self-tuning robotic zither.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Barton, S., Prihar, E., & Carvalho, P. (2017). Cyther: a human-playable, self-tuning robotic zither. In The International Conference on New Interfaces for Musical Expression (pp. 319-324).
            </ul>

            <br></br>

            <h2>
                Poster Papers
            </h2>

            <h3>
                Comparing Different Approaches to Generating Mathematics Explanations Using Large Language Models
                <a style={link_style} href='publications/Comparing Different Approaches to Generating Mathematics Explanations Using Large Language Models.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Prihar, E., Lee, M., Hopman, M., Kalai, A. T., Vempala, S., Wang, A., Wickline, G., Murray, A., & Heffernan, N. (July, 2023). Comparing Different Approaches to Generating Mathematics Explanations Using Large Language Models. In Artificial Intelligence in Education (pp. 290-295). Springer, Cham.
            </ul>

            <h3>
                More Powerful A/B Testing using Auxiliary Data and Deep Learning
                <a style={link_style} href='publications/More Powerful AB Testing using Auxiliary Data and Deep Learning.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Sales, A., Prihar, E., Gagnon-Bartsch, J., Gurung, A., & Heffernan, N. T. (July, 2022). More Powerful A/B Testing using Auxiliary Data and Deep Learning. In Artificial Intelligence in Education (pp. 524-527). Springer, Cham.
            </ul>

            <h3>
                Student Engagement During Remote Learning
                <a style={link_style} href='publications/Student Engagement During Remote Learning.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Prihar, E., Botelho, A., Yuen, J., Corace, M., Shanaj, A., Dia, Z., & Heffernan, N. (April, 2021). Student Engagement During Remote Learning. In Companion Proceedings 11th International Conference on Learning Analytics & Knowledge (pp. 49-51).
            </ul>

            <br></br>

            <h2>
                Workshop and Consortium Papers
            </h2>

            <h3>
                Assessing the Quality of Large Language Models in Generating Mathematics Explanations
                <a style={link_style} href='publications/Assessing the Quality of Large Language Models in Generating Mathematics Explanations.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Wang, A., Prihar, E., & Heffernan, N. (2023, July). Assessing the Quality of Large Language Models in Generating Mathematics Explanations. In the Fourth Annual Workshop on A/B Testing and Platform-Enabled Learning Research at L@S 2023 (pp. 1-5).
            </ul>

            <h3>
                Identifying Explanations Within Student-Tutor Chat Logs
                <a style={link_style} href='publications/Identifying Explanations Within Student-Tutor Chat Logs.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Prihar, E., Moore, A., & Heffernan, N. (2022, July). Identifying Explanations Within Student-Tutor Chat Logs. In Proceedings of the 15th International Conference on Educational Data Mining Doctoral Consortium (pp. 1-5). International Educational Data Mining Society.
            </ul>

            <h3>
                Toward Improving Effectiveness of Crowdsourced, On-Demand Assistance from Educators in Online Learning Platforms
                <a style={link_style} href='publications/Toward Improving Effectiveness of Crowdsourced, On-Demand Assistance From Educators in Online Learning Platforms.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Haim, A., Prihar, E., & Heffernan, N. (2022, June). Toward Improving Effectiveness of Crowdsourced, On-Demand Assistance From Educators in Online Learning Platforms. In Artificial Intelligence in Education. Posters and Late Breaking Results, Workshops and Tutorials, Industry and Innovation Tracks, Practitioners' and Doctoral Consortium (pp. 29-34). Springer, Cham.
            </ul>

            <h3>
                MathBERT A Pre-trained Language Model for General NLP Tasks in Mathematics Education
                <a style={link_style} href='publications/MathBERT A Pre-trained Language Model for General NLP Tasks in Mathematics Education.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Shen, J. T., Yamashita, M., Prihar, E., Heffernan, N., Wu, X., Graff, B., & Lee, D. (December, 2021). MathBERT: A Pre-trained Language Model for General NLP Tasks in Mathematics Education. In NeurIPS 2021 Math AI for Education Workshop (pp. 1-10).
                <br></br>
                <div style={gold_tag_style}>Best Paper in Workshop</div>
            </ul>

            <h3>
                Identifying Anomalous Students: Process and Benefits
                <a style={link_style} href='publications/Identifying Anomalous Students Process and Benefits.pdf' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Prihar, E., & Heffernan, N. (July, 2020). Identifying Anomalous Students: Process and Benefits. In the Causal Inference Workshop @ EDM2020 (pp. 1-6).
            </ul>

            <hr style={line_style}></hr>

            <h1>TEACHING AND MENTORSHIP</h1>

            <h2>
                Courses
            </h2>

            <h3>User Modeling<i> — WPI, Fall 2022</i></h3>
            <ul>
                I co-instructed a graduate class where I covered diverse topics in user modeling, led group discussions with guest lecturers, and supervised students as they developed a final project involving modeling user behavior within an online learning platform.
            </ul>

            <h3>Introduction to Artificial Intelligence<i> — WPI, Fall 2016</i></h3>
            <ul>
                As a student assistant for the Introduction to Artificial Intelligence class, I created test materials, reviewed student work, and held office hours each week for a class of around 60 students.
            </ul>

            <h3>Wilderness Survival<i> — UHart, Summer 2014</i></h3>
            <ul>
                I designed and taught a wilderness survival course for groups of 10 - 13 year olds in which attendees learned various outdoor skills such as identifying edible wild plants, building survival shelters, and making fires.
            </ul>

            <br></br>

            <h2>
                Workshops
            </h2>

            <h3>Causal Inference in Educational Data Mining<i> — EDM, Summer 2021</i></h3>
            <ul>
                I helped organize the Causal Inference in Educational Data Mining Workshop at the 2021 Educational Data Mining in which I presented on the challenges faced when attempting to adaptively personalize tutoring within online learning platforms.
            </ul>

            <h3>Introduction to Python for Data Analysis<i> — WPI, Summer 2021</i></h3>
            <ul>
                I created and taught a workshop for around two dozen WPI students and faculty in which I helped attendees configure a Python environment for data analysis and guided them through typical analysis tasks.
            </ul>

            <h3>How do Neural Networks Work?<i> — Pratt & Whitney, Spring 2019</i></h3>
            <ul>
                I gave a series of lectures open to the entire engineering discipline on neural networks. In these lectures I explained the architecture of feed-forward neural networks and walked attendees through the mathematics behind making a prediction and learning through backpropigation.
            </ul>

            <br></br>

            <h2>
                Mentorship
            </h2>

            <h3>Creating Features for Personalized Tutoring in ASSISTments
                <a style={link_style} href='https://digital.wpi.edu/downloads/jd473067n' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Scopetski, C., & Hendrickson, M. (2022). Creating Features for Personalized Tutoring in ASSISTments (Interactive Qualifying Project, Worcester Polytechnic Institute).
            </ul>

            <h3>Contextual Bandit Approaches to Personalized Tutoring
                <a style={link_style} href='https://digital.wpi.edu/downloads/4t64gr52p' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Healy, A. (2022). Contextual Bandit Approaches to Personalized Tutoring (Major Qualifying Project, Worcester Polytechnic Institute).
            </ul>

            <h3>Applying Reinforcement Learning Based Tutor Strategy Recommendation Service To The ASSISTments
                <a style={link_style} href='https://digitalwpi.wpi.edu/downloads/x633f401d' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Dai, Z. (2021). Applying Reinforcement Learning Based Tutor Strategy Recommendation Service To The ASSISTments (Master's Thesis, Worcester Polytechnic Institute).
            </ul>

            <h3>A Review of Educational Data Mining Features
                <a style={link_style} href='https://digital.wpi.edu/downloads/cz30pw79m' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Cosma, B. (2021). A Review of Educational Data Mining Features (Interactive Qualifying Project, Worcester Polytechnic Institute).
            </ul>

            <h3>Data Fetcher
                <a style={link_style} href='https://digital.wpi.edu/downloads/t722hc80k' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Shanaj, A. (2021). Data Fetcher (Interactive Qualifying Project, Worcester Polytechnic Institute).
            </ul>

            <h3>
            Developing Infrastructure for Reinforcement Learning in Assistments
                <a style={link_style} href='https://digital.wpi.edu/downloads/6682x666q' download>
                    <span> </span><RiFileDownloadLine style={{marginBottom: '-3px'}}/>
                </a>
            </h3>
            <ul>
                Kumar, A., & Grant, P. W. (2021). Developing Infrastructure for Reinforcement Learning in Assistments (Interactive Qualifying Project, Worcester Polytechnic Institute).
            </ul>

            <hr style={line_style}></hr>

            <h1>WORK EXPERIENCE</h1>

            <h3>École Polytechnique Fédérale de Lausanne, Lausanne, Switzerland<i> — Postdoctoral Researcher</i></h3>
            <p><strong>September 2023 - Present</strong></p>
            <ul>
                In the Machine Learning for Education lab I am responsible for advising PhD students and developing novel methods of integrating reinforcement learning into online learning platforms.
            </ul>

            <h3>Worcester Polytechnic Institute, Worcester, MA<i> — Research Assistant, Data Science</i></h3>
            <p><strong>July 2019 - July 2023</strong></p>
            <ul>
                In the ASSISTments lab I was responsible for projects and grants that revolve around personalized education through machine learning, modeling student behavior, and aggregating data sets for the learning and data science communities.
            </ul>

            <h3>Boys and Girls Club, Worcester, MA<i> — Music Room Instructor</i></h3>
            <p><strong>February 2023 - May 2023</strong></p>
            <ul>
                At the Boys and Girls Club I worked with 8-16 year old members by providing beginner-level lessons in piano, drums, and guitar, and recording and producing their original songs.
            </ul>

            <h3>Pratt & Whitney, East Hartford, CT<i> — Software Engineer, Machine Learning</i></h3>
            <p><strong>April 2018 - July 2019</strong></p>
            <ul>
                In the machine learning team at Pratt & Whitney I designed and implemented models of jet engine behavior, which were used to identify degredation and anticipate future malfunctions.
            </ul>

            <h3>Pratt & Whitney, East Hartford, CT<i> — Associate Software Engineer, Controls</i></h3>
            <p><strong>January 2017 - April 2018</strong></p>
            <ul>
                As a controls software engineer at Pratt & Whitney I designed and implemented algorithms to control the behavior of jet engines during flight.
            </ul>

            <h3>Northrop Grumman, Redondo Beach, CA<i> — Beam Controls Intern</i></h3>
            <p><strong>May - July 2015, May - July 2016</strong></p>
            <ul>
                At Northrop Grumman I designed and created program validation and test plans, support hardware, and analysis software.
            </ul>

            <h3>Tribal Engineering, Long Beach, CA<i> — Prototype Designer</i></h3>
            <p><strong>July 2015 - July 2016</strong></p>
            <ul>
                At Tribal Engineering I designed and fabricated a prototype product with the intent of maximizing interconnectivity in the home.
            </ul>

            <hr style={line_style}></hr>

            <h1>EDUCATION</h1>

            <h2>Ph.D. in Data Science — Worcester Polytechnic Institute</h2>
            <p><strong>August 2021 - May 2023</strong></p>
            <ul>
                <li>
                    Dissertation
                    <a style={link_style} href='https://digitalwpi.wpi.edu/concern/etds/ft848v176?locale=en' target='_blank' rel='noreferrer'>
                        <span> </span><RiExternalLinkLine style={{marginBottom: '-3px'}}/>
                    </a>
                </li>
                <li>GPA: 4.00</li>
            </ul>

            <h2>Master of Science — Worcester Polytechnic Institute</h2>
            <p><strong>August 2019 - May 2021</strong></p>
            <ul>
                <li>
                    Thesis
                    <a style={link_style} href='https://digitalwpi.wpi.edu/concern/etds/5d86p315b?locale=en' target='_blank' rel='noreferrer'>
                        <span> </span><RiExternalLinkLine style={{marginBottom: '-3px'}}/>
                    </a>
                </li>
                <li>Major: Data Science</li>
                <li>GPA: 4.00</li>
            </ul>

            <h2>Bachelor of Science — Worcester Polytechnic Institute</h2>
            <p><strong>August 2013 - February 2017</strong></p>
            <ul>
                <li>
                    Major Qualifying Project
                    <a style={link_style} href='https://digitalwpi.wpi.edu/concern/student_works/9306t103h?locale=en' target='_blank' rel='noreferrer'>
                        <span> </span><RiExternalLinkLine style={{marginBottom: '-3px'}}/>
                    </a>
                </li>
                <li>Major: Robotics Engineering</li>
                <li>Minor: Music</li>
                <li>GPA: 3.83</li>
            </ul>

            <hr style={line_style}></hr>

            <br></br><br></br><br></br>
        </div>
        );
    }
}

export default CurriculumVitae
